<template>
    <div class="w100 bs">
        <div class="w100 bgc_fff" style="border-radius: 2px;min-height: calc(100vh - 180px);">
            <div class="bs w100 pos-tabs-box flex align-center justify-between">
                <el-tabs v-model="activeName" class="pos-tabs bs shrink_0" @tab-click="handleClick">
                    <el-tab-pane label="职位统计" name="first"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="text-14 c_262626 w100 bs flex align-center" style="padding: 24px;">
                <div class="flex align-center">
                    <div>发布时间：</div>
                    <el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" v-model="releaseDate" type="daterange" unlink-panels range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" />
                </div>

                <div class="flex align-center" style="margin-left:24px; width:30%;">
                    <div>职位状态：</div>
                    <el-select class="flex-sub" v-model="mSalary" placeholder="请选择职位状态" clearable size="large">
                        <el-option v-for="item in mSalaryOp" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
            </div>
            <div class="w100 bs" style="padding:0 24px;">
                <div class="w100" >
                    <el-table :data="tableData" style="width: 100%" header-cell-class-name="bgc_fa">
                        <el-table-column fixed prop="id" label="职位ID" width="100" align="center" />
                        <el-table-column prop="name" label="职位名称" align="center" show-overflow-tooltip />
                        <el-table-column prop="cusState" label="职位状态" align="center" />
                        <el-table-column prop="onlineTime" label="发布时间" align="center" />
                        <el-table-column prop="ext.viewCount" label="访问人数" width="88" align="center" />
                        <el-table-column prop="ext.applyCount" label="投递人数" width="88" align="center" />
                        <el-table-column prop="jobCount" label="招聘人数" width="88" align="center" />
                        <el-table-column prop="ext.waitBackCount" label="待反馈" width="88" align="center" />
                        <el-table-column prop="ext.waitUndeterminedCount" label="待定" width="88" align="center" />
                        <el-table-column prop="ext.suitableCount" label="合适" width="88" align="center" />
                        <el-table-column prop="ext.notSuitableCount" label="不合适" width="88" align="center" />
                    </el-table>
                    <div class="mt_20 w100 bs flex justify-end">
                        <el-pagination lazy layout="total ,prev,pager,next,sizes,jumper" :total="dateTotle"
                            @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="psArr" style="font-size: 20px">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { listHunterJob } from '@/util/apis'
export default defineComponent({
    name: "",
    data() {
        return {
            activeName: 'first',
            releaseDate: '' as any,
            mSalary: '',
            mSalaryOp: [{label:'待发布',value:'0'},{label:'已上线',value:'1'},{label:'已下线',value:'2'}] as any,
            tableData: [] as any,
            currentPage: 1,
            pagesize: 10,
            psArr: [10, 20, 30],
            dateTotle:0,
            startDate:'',
            endDate:''
        }
    },
    mounted(){
        this.getList();
    },
    watch:{
        mSalary(newVal:any,oldVal:any){
            this.mSalary = newVal || '';
            this.getList();
        },
        releaseDate(newVal:any,oldVal:any){
            this.startDate = newVal? newVal[0] : '';
            this.endDate = newVal? newVal[1] : '';
            this.getList();
        }
    },
    methods: {
        handleClick(tab: any, event: Event) {
            console.log(tab, event)
        },
        handleSizeChange(size: number) {
            this.pagesize = size;
            this.getList();
        },

        handleCurrentChange(currentPage: number) {
            this.currentPage = currentPage;
            this.getList();
        },
        getList() {
            listHunterJob({
                page: this.currentPage,
                pageSize: this.pagesize,
                state: this.mSalary,
                startDate: this.startDate,
                endDate: this.endDate
            }).then(res => {
                res.data.list.forEach((element: any) => {
                    element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、');
                    element.cusState = element.state == '0' ? '未上线' : (element.state == '1'? '已上线':'已下线');
                    element.cusDate = element.updateDate.substring(0,10);
                });
                this.tableData = res.data.list;
                this.dateTotle = res.data.total;
            })
        }
    },
    setup: () => {

    }
})
</script>
<style scoped>
@import "./index.css";
</style>